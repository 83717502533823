Fundu.Helpers.Defaults = (function() {

    // Public Methods
    ///////////////////////////

    // Default response errors, if the function returns true
    // you should set the components _error to true (require page reload)
    var responseError = function(area, error){
        if (error === "error") {
            Fundu.Helpers.Notify.error("registration", "error.try_again");
        } else if (error === "parsererror") {
            Fundu.Helpers.Notify.error("registration", "error.generic");
            return true;
        } else if (error === "timeout") {
            Fundu.Helpers.Notify.error("registration", "error.no_connection");
        } else if (error === "abort") {
            Fundu.Helpers.Notify.error("registration", "error.aborted");
        }

        return false;
    }

    return {
        response: {
            errors: responseError
        }
    };
})();
