/*
 * module.js - The description of the module.
 */

Fundu.Helpers.Notify = (function($) {

    // Properties
    ///////////////////////////
    var _areas = false;
    var _areaClassSelector = ".notify";
    var _areaTimeoutBuffer = {};
    var _onLoadNotifications = false;
    var _timeout = false;
    var _timeoutLength = 4000;

    var _navHeader = false;
    var _globalNotify = false;

    // Private Methods
    ///////////////////////////
    var selectElements = function() {
        _navHeader = $("#header");
        _globalNotify = $(".global.notify")
    }

    var discoverAreas = function() {
        _areas = new Array();
        // Get a list of classes used as selector
        // by removing all dots
        var filterClasses = _areaClassSelector.substr(1, _areaClassSelector.length)
            .replace(/\./gi, ' ')
            .split(/\s+/);
        // Add common classes
        filterClasses.push("show");
        filterClasses.push("hide");
        filterClasses.push("container");
        filterClasses.push("message");

        // Select the _areas and add them to the list
        $(_areaClassSelector).each(function(index) {
            var area = this.className;

            // Remove all the selector classes
            for (var j = 0; j <= filterClasses.length; j++) {
                area = area.replace(filterClasses[j], '');

                // Let's not waste time checking if no classes are left
                if (area.replace(/\s+/gi, '') === "")
                    break;
            }

            // Replace any whitespaces that are left
            area = area.replace(/\s+/gi, '');

            // If this area has a page load error (printed on page load) add it to list
            // The list will be processed by the _timeout
            if ($(this).hasClass('show')) {
                if (!_onLoadNotifications) {
                    _onLoadNotifications = new Array();
                }

                _onLoadNotifications.push(area);

                // Remove the show classses
                $(this).removeClass('show').velocity("fadeIn", 0);
            }
            else{
                // Remove the hide classses
                $(this).removeClass('hide').velocity("fadeOut", 0);
            }

            // Add to _areas
            _areas[area] = $(this);
        });
    }

    var checkArea = function(area) {
        if (area in _areas)
            return true;
        else {
            console.error("No such area found", area);
            return false;
        }
    }

    // _timeout the error hiding. Each time this
    // function is called, the _timeout is reseted
    var _timeoutHide = function(area, length) {
        // Defaults
        // Default length to _timeoutLength
        if (typeof length === "undefined" || typeof length !== "number"){
            length = _timeoutLength;
        }

        if (_areaTimeoutBuffer && area in _areaTimeoutBuffer) {
            clearTimeout(_areaTimeoutBuffer[area]);
        }

        //hide error
        _areaTimeoutBuffer[area] = setTimeout(
            function() {
                hideNotification(this);
            }.bind(area), length
        );
    }

    // Hide immedietly the error
    var hideNotification = function(area) {
        // Remove any previous commands
        if (_areaTimeoutBuffer && area in _areaTimeoutBuffer) {
            clearTimeout(_areaTimeoutBuffer[area]);
        }

        // Only if we are not hidden, hide fast
        if(_areas[area].css('opacity') !== "0"){
            _areas[area].html();
            _areas[area].attr("style", "display:none;opacity:0;");
        }
    }

    var translate = function(message, value1, value2) {
        if (!message || message === "")
            return false;

        //Ensure the message is a string
        if (typeof message !== "string")
            message = message.toString();
        // Prepend the js. infront of the message incase it is missing
        if (message.substr(0, 3) === "js.")
            message = message.replace("js.");

        // Translate the message
        if(typeof value1 !== "undefined"){
            if( typeof value2 !== "undefined")
                var translated = Msg(message, value1.toString(), value2.toString());
            else
                var translated = Msg(message, value1.toString())
        }
        else{
            var translated = Msg(message);
        }
        // If the string hasn't changed, no translation was found
        if (translated === message) {
            return false;
        }

        return translated;
    }

    var translateYesOrNo = function(value) {
        if(value) return translate("common.term.yes")
        else return translate("common.term.no")
    }

    var fadeOut = function(element){
        // is this already hidden
        if(element.css('opacity') !== "0" ){
            element.velocity("fadeOut", 300, function(){
              // Double check that we are hidden
              if(element.css('opacity') !== "1"){
                element.removeClass("success error warning note");
              }
            });
        }
    }

    var fadeIn = function(element){
        // Is this already visible
        if($(element).css('opacity') !== "1" ){
            element.velocity("fadeIn", 300);
        }
    }

    // Display an error
    var showNotification = function(type, area, message, skip, length) {
        if( !type || typeof message !== "string" ) return false;
        if( !message || typeof message !== "string" ) return false;

        // Make skips default value to false
        if (typeof skip === "undefined" || typeof skip !== "boolean") {
            skip = false;
        }

        // Default length to _timeoutLength
        if (typeof length === "undefined" || typeof length !== "number") {
            length = _timeoutLength;
        }


        if (checkArea(area)) {
            //Remove all special characters except whitespace, dot (.), -, _ characters
            message = message.replace(/[^\w\s\.\-\_]/gi, '');

            // If this is a no recoverable error, don't hide the message
            if (message === "js.common.failed" ||
                message === "common.failed") {

                // Clear the _timeout buffer for this area
                if (_areaTimeoutBuffer && area in _areaTimeoutBuffer) {
                    clearTimeout(_areaTimeoutBuffer[area]);
                }
            } else if (length > 0) {
                _timeoutHide(area, length);
            }

            if (!skip) {
                var translated = translate(message);

                // If failed
                if (!translated) {
                    console.error("No such translation found", message);
                    return false;
                }
                _areas[area].html();
                _areas[area].text(translated);

            } else {
                _areas[area].html();
                _areas[area].text(message);
            }
            // Finish the previous animation
            _areas[area].velocity("finish");

            // Set type
            _areas[area].attr('class', area + " notify " + type);
            _areas[area].visible = true;

            //Check is the error already visible
            fadeIn(_areas[area]);
            return true;
        }

        return false;
    }

    var detachGlobalError = function() {
        if (_globalNotify.length > 0) {
            $(window).scroll(function () {
                var scroll = $(window).scrollTop();
                var navHeight = _navHeader.length > 0 ? _navHeader.height() : 0;

                if (scroll >= navHeight) {
                    _globalNotify.addClass("fixedPosition");
                } else {
                    _globalNotify.removeClass("fixedPosition");
                }
            });
        }
    }


    // Public Methods
    ///////////////////////////

    var html = function(type, area, html, length) {
        if(typeof type === "undefined") return false;
        if(typeof _areas === "undefined") return false;
        if(typeof length === "undefined") length = _timeoutLength;

        //We must have a 'valid' type
        if(type !== "error" && type !== "warning" && type !== "success" && type !== "note")
            return false;

        if (checkArea(area)) {

            // Finish the previous animation
            _areas[area].velocity("finish");

            // Prepare area, ensure that it is before velocity (otherways it will be removed)
            _areas[area].attr('class', area + " notify " + type);

            // Empty and the set html
            _areas[area].html(html);
            _areas[area].visible = true;

            // If this is a no recoverable error, don't hide the message
            if (length > 0) {
                _timeoutHide(area, length);
            }

            //Check is the error already visible
            fadeIn(_areas[area]);
        }

        return false;
    }

    var hide = function(area){

        // Hide all areas
        if(!area || typeof area !== "string"){
            for( var area in _areaTimeoutBuffer ){
                hideNotification(area);
            }
        }
        // Hide selected area
        else{
            if( !checkArea(area) ) return false;
            hideNotification(area);
        }
    }

    // Write success
    var notify = function(area, message, skip, timeout) {
        if(!_areas) return false;

        // Make skips default value to false
        if (typeof skip === "undefined" || typeof skip !== "boolean") {
            skip = false;
        }

        // Console messages are never translated
        if (area === "console") {
            //console.error(message);
            return true;
        } else {
            return showNotification("note", area, message, skip, timeout);
        }
    }

    // Write success
    var success = function(area, message, skip, timeout) {
        if(!_areas) return false;

        // Make skips default value to false
        if (typeof skip === "undefined" || typeof skip !== "boolean") {
            skip = false;
        }

        // Console messages are never translated
        if (area === "console") {
            //console.error(message);
            return true;
        } else {
            return showNotification("success", area, message, skip, timeout);
        }
    }

    // Write success
    var warning = function(area, message, skip, timeout) {
        if(!_areas) return false;

        // Make skips default value to false
        if (typeof skip === "undefined" || typeof skip !== "boolean") {
            skip = false;
        }

        // Console messages are never translated
        if (area === "console") {
            //console.error(message);
            return true;
        } else {
            return showNotification("warning", area, message, skip, timeout);
        }
    }

    // Write error
    var error = function(area, message, skip, timeout) {
        if(!_areas) return false;

        // Make skips default value to false
        if (typeof skip === "undefined" || typeof skip !== "boolean") {
            skip = false;
        }

        // Console messages are never translated
        if (area === "console") {
            //console.error(message);
            return true;
        } else {
            return showNotification("error", area, message, skip, timeout);
        }
    }

    var getAreas = function() {
        return _areas;
    }

    // Init
    ///////////////////////////
    $(function() {
        selectElements();
        // Discover all _areas where notifications can be added
        discoverAreas();

        // Check was there any "pre-rendered" messages
        if (_onLoadNotifications) {
            $(_onLoadNotifications).each(
                function (index) {
                    _timeoutHide(this); // Page load errors are hidden after _timeout
                });
        }

        detachGlobalError();
    });

    // Reveal public methods
    return {
        html: html,
        translate: translate,
        translateYesOrNo: translateYesOrNo,
        error: error,
        notify: notify,
        success: success,
        warning: warning,
        getAreast: getAreas,
        hide: hide
    };
})(jQuery);
