Fundu.Pages.Common = (function($) {

    // State
    var _utilsLoaded = false;

    // Properties
    ///////////////////////////
    var _transactionMenuElement = false;
    var _allTransactionCollapses = false;
    var _phoneValidateTimeout = false;
    var _phoneValidateElement = false;
    var _listeners = false;
    var _mobileMenu = false;
    var _subMenus = false;

    // Private Methods
    ///////////////////////////
    var selectElements = function() {
        _transactionMenuElement = $("#transaction-menu");
        _allTransactionCollapses = $(_transactionMenuElement).find('.collapse');
    }

    var closeTransactionMenus = function(ev){
        if(ev)
            ev.preventDefault();

        _allTransactionCollapses.collapse('hide');
    }

    var accordionTransactionMenus = function(ev){
        _allTransactionCollapses.collapse('hide');
    }

    var toggleReadMore = function(ev){
        if(ev)
            ev.preventDefault();

        $(this).parent().toggleClass('show');
    }

    var formatPhone = function(number, countryCode){
        if(!number || !countryCode || typeof intlTelInputUtils === "undefined") return number;
        return intlTelInputUtils.formatNumber( number, countryCode, intlTelInputUtils.numberFormat.INTERNATIONAL);
    }

    var validatePhone = function(element){
        if(_phoneValidateElement.length === 0) return false;

        if(_phoneValidateTimeout)
            clearTimeout(_phoneValidateTimeout);

        _phoneValidateTimeout = setTimeout(
            function(){
                var result = $(element).intlTelInput("isValidNumber");
                if(result){
                    var className = "success";
                }
                else{
                    var className = "fail";
                }

                _phoneValidateElement.attr("class", className + " validation");
            }, 80);
    }

    var intializePhoneComponent = function(){

        var phoneInputs = $("#phoneNumber");

        if(phoneInputs.length > 0){
            // Asume only one phoneinput per page
            _phoneValidateElement = phoneInputs.parent(".form-group").find(".validation");

            phoneInputs.intlTelInput({
                autoHideDialCode: true,
                initialCountry: "fi",
                preferredCountries: ["fi", "se"],
                nationalMode: false,
                formatOnInit: true,
                utilsScript: "/scripts/utils.js"
            }).done(
                function(){
                    // Format input when input or country changes
                    phoneInputs.on("countrychange",
                        function(e, countryData) {
                            this.value = formatPhone(this.value, countryData.iso2);
                            validatePhone(this);
                        }
                    );

                    phoneInputs.on("blur",
                        function(e){
                            var countryCode = $(this).intlTelInput("getSelectedCountryData").iso2;
                            this.value = formatPhone(this.value, countryCode);
                            validatePhone(this);
                        }
                    );

                    $("#phoneNumber").trigger("blur");

                    _utilsLoaded = true;
                    triggerLoaded();
                }
            );
        }
    }

    var triggerLoaded = function(event){
        for(var index in _listeners){
            _listeners.pop().call();
        }
    }

    var initializeComponents = function(){
        if("Simulate" in Fundu.Utils){
            Fundu.Utils.Simulate.init();
        }

        $('.read-more .toggle-read-more').on('click', toggleReadMore);

        $('.selectpicker').selectpicker();

        $('[data-toggle="tooltip"]').tooltip();

        intializePhoneComponent();
        backToTop();

        if(_allTransactionCollapses.length > 0){
            _allTransactionCollapses.on('show.bs.collapse', accordionTransactionMenus);
            _allTransactionCollapses.find('.close.btn').on('click', closeTransactionMenus);
        }

        _mobileMenu = $('#mobile-menu');

        // Toggle menu on burger button
        $('.navbar-toggle').on('click', function(ev){
            if(ev)
                ev.preventDefault();

            _mobileMenu.toggleClass('hide');
            return false;
        });

        // Close menu on click
        $(_mobileMenu).find('link a').on("click", function(ev){
            if(ev)
                ev.preventDefault();

            _mobileMenu.toggleClass('hide');
            return false;
        });
    }

    var backToTop = function(){
        // browser window scroll (in pixels) after which the "back to top" link is shown
        var screenHeight = $( window ).height();
        var offset = Math.floor( screenHeight * 0.15 ),

            //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
            opacityOffset = Math.floor( screenHeight * 0.65 ),

            //duration of the top scrolling animation (in ms)
            duration = 700,
            //grab the "back to top" link
            $backToTop = $('.back-to-top');

        var calculateNewHeight = false;

        function classTests(element){
            ( $(element).scrollTop() > offset ) ? $backToTop.addClass('is-visible fade-out') : $backToTop.removeClass('is-visible fade-out');
            if( $(element).scrollTop() >= opacityOffset ) {
                $backToTop.removeClass('fade-out');
            }
        }

        //hide or show the "back to top" link
        $(window).scroll(function(ev){
            classTests(this);
        });

        $(window).resize(function(ev){
            if(calculateNewHeight)
                clearTimeout(calculateNewHeight);

            setTimeout(
                function(){
                    screenHeight = $( window ).height();
                    offset = Math.floor( screenHeight * 0.15 );

                    //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
                    opacityOffset = Math.floor( screenHeight * 0.65 );

                    classTests(window);
                }, 80);
        });

        //smooth scroll to top
        $backToTop.on('click', function(ev){
            if(ev)
                event.preventDefault();

            $('body, html').velocity('scroll',{duration: duration, offset: 0 });;
        });

        // Check on load
        classTests(window);
    }

    // Init
    ///////////////////////////

    var initialize = function() {
        selectElements();
        initializeComponents();

        return $.Deferred().resolve(true);
    }

    var finalize = function() {
        // Hide login button from navigation
        $('.preloader').velocity("fadeOut", 250, function() {
            $('body').removeClass('loading');
        });
    }

    // Add listener for utils loaded
    var addListener = function(func){

        // Already done
        if(_utilsLoaded){
            func.call();
        // Add listener
        } else {
            if(!_listeners)
                _listeners = [];

            _listeners.push(func);
        }
    }

    // Reveal public methods
    return {
        init: initialize,
        finalize: finalize,
        addListener: addListener
    }
})(jQuery);
