/*
 * module.js - The description of the module.
 */

Fundu.Components.Sms = (function($) {
    // State
    ///////////////////////////
    var _isInitialized = false;
    var _hasPhoneNumber = false;
    var _error = false;
    var _sending = false;
    var _numberSet = false;
    var _regComplete = false;

    // Properties
    ///////////////////////////
    var _smsContainerElement = false;
    var _stepOneInputNumberElement = false;
    var _stepTwoVerifyCodeElement = false;
    var _phoneNumberElement = false;
    var _phoneNumberInput = false;
    var _codeInput = false;
    var _sendSMSButton = false;
    var _resendSMSButton = false;
    var _verifySMSButton = false;
    var _successUrl = false;
    var _failUrl = false;
    var _action = false;
    var _messageQuota = false;
    var _stepTwoVisible = false;
    var _rememberNumber = false;
    var _waitTime = 198000; //3.3mmin, backend sends 3min to Nexmo


    // Private Methods
    ///////////////////////////

    // Select elements from HTML
    var selectElements = function() {
        _smsContainerElement = $('#smsContainer');
        _stepOneInputNumberElement = $('#smsForm .step-input');
        _stepTwoVerifyCodeElement = $('#smsForm .step-verify');
        _phoneNumberElement = $('#phoneNumber');
        _phoneNumberInput = $('#smsForm input[name="phone"]');
        _codeInput = $('#smsCode');
        _sendSMSButton = $('#smsForm .send.btn');
        _resendSMSButton = $('#smsForm .resend.btn');
        _verifySMSButton = $('#smsForm .verify.btn');
        _successUrl = $('#smsForm input[name="successUrl"]').val();
        _failUrl = $('#smsForm input[name="successUrl"]').val();
        _action = $('#smsForm input[name="action"]').val();
    }

    // Check that the elements exist and unbind them
    var checkElements = function() {
        if( _phoneNumberInput.val() !== "" )
            _hasPhoneNumber = true;
        else
            _hasPhoneNumber = false;

        // TO-DO: Check success and fail urls
        _codeInput.val("");

        _sendSMSButton.on('click', send);
        _resendSMSButton.on('click', reSend);
        _verifySMSButton.on('click', verify);
        return true;
    };

    // Move to validate.js
    var validateNumber = function(){
        // Checkc is this baked phone number (found in hidden input) or does this expect phone input
        if(_hasPhoneNumber)
            return intlTelInputUtils.isValidNumber(_phoneNumberInput.val());
        else
            return intlTelInputUtils.isValidNumber(_phoneNumberElement.val());
    }

    var setStatus = function(status){
        if(!status || typeof status !== "string") return false;

        switch (status) {
            case "sending":

                break;
            case "sent":
                break;
            case "resending":
                break;
            case "complete":
                break;
        }

        return false;
    }

    var validateCode = function(){
        var code = $(_codeInput).val();
        if(code && typeof code === "string" && code.length === 4 && parseInt(code) !== NaN )
            return true;
        else
            return false;
    }

    var showVerify = function(){
        if(!_stepTwoVisible){
            _stepOneInputNumberElement.velocity({opacity: 0},200,
                function(){
                    _stepOneInputNumberElement.css('display', 'none');
                    _stepTwoVerifyCodeElement.velocity("fadeIn", 300);
                    _stepTwoVisible = true;
                }
            );
        }
    }

    var finalize = function(){

        Fundu.Helpers.Notify.success("sms", "success.sms.accepted");
        if(_action.toLowerCase() === "registration"){
            setPhoneNumber().done(function(){
                if(_numberSet) completeRegistration().done(
                    function(){
                        if(_regComplete){
                            window.location.href = _successUrl;
                        }
                });
            });
        } else {
            window.location.href = _successUrl;
        }
    }

    var setPhoneNumber = function(){

        //TO-DO: Auto retry if connection problems
        //if age reloads, save marking for 5min that allows user
        //readd the code

        // Build data
        var data = {
            phoneNumber: _rememberNumber.replace(/\s+/gi, '')
        };

        // Send sms message
        return $.ajax({
            type: "POST",
            contentType: "application/json",
            data: JSON.stringify(data),
            url: jsRoutes.controllers.RegistrationCtrl.setPhoneNumber().url,
            cache: false
        })

        .done(function (res) {
            _numberSet = true;
        })

        .fail(function (res, error) {
            // 406 is not returned as JSON, why??
            if (res && res.responseJSON && "error" in res.responseJSON){
                // Skip timeout with 0
                Fundu.Helpers.Notify.error("sms", res.responseJSON.error, 3000);
            } else {
                _error = Fundu.Helpers.Defaults.Response.error("sms", error);
            }
            _numberSet = false;
        });
    }

    var completeRegistration = function(){

        return $.ajax({
            type: "GET",
            contentType: "application/json",
            url: jsRoutes.controllers.RegistrationCtrl.completeSmsIdentification().url,
            cache: false
        })

        .done(function (res) {
            _regComplete = true;
        })

        .fail(function (res, error) {
            // 406 is not returned as JSON, why??
            if (res && res.responseJSON && "error" in res.responseJSON){
                // Skip timeout with 0
                Fundu.Helpers.Notify.error("sms", res.responseJSON.error, 3000);
            } else {
                _error = Fundu.Helpers.Defaults.Response.error("sms", error);
            }

            _regComplete = false;
        });
    }


    // Init
    ///////////////////////////
    var initialize = function(action) {
        if(_isInitialized) return true;

        selectElements();

        // If action not set, check is it baked in
        if(!action || typeof action !== "string"){
            if(!_action) return false;  //If no baked in value given, fail
            else action = _action; //If baked in value given, use it
        }

        // TO-DO: Check action (limited amount of alowed actions)

        var success = checkElements();
        if (success) {

            _messageQuota = true;
            _action = action;

            // if we have a phonenumber baked in the form, send sms
            if(_hasPhoneNumber){
                _stepTwoVisible = true;
                send();
            }
            // Prepare verification transition
            else {
                if (_stepTwoVerifyCodeElement.hasClass('hide')) {
                    _stepTwoVerifyCodeElement.removeClass('hide')
                        .css("opacity", 0)
                        .css('display', 'none');
                }
            }
            _isInitialized = true;
            return true;
        }

        return false;
    }


    var send = function(ev, resending){
        if(ev)
            ev.preventDefault();

        if(_error || _sending) return false;

        // Default values
        if(typeof resending === "undefined") resending = false;

        // Check quota
        if(_messageQuota ){

            // Check that the number is still correct
            if(validateNumber()){

                _sending = true;

                // Ensure the number can't change in between requests
                _rememberNumber = (_hasPhoneNumber ? _phoneNumberInput.val() : _phoneNumberElement.val()).replace(/\s/g, "");

                // Build data
                var data = {
                    action: {
                        name: _action.toLowerCase()
                    },
                    successUrl: _successUrl,
                    failUrl: _failUrl,
                        // Get number from hidden input or visible input and remove spaces
                    phoneNumber: _rememberNumber.replace(/\s+/gi, '')
                };

                // Notify about sending
                if(resending){
                    Fundu.Helpers.Notify.notify("sms", "identification.sms.state.resend.sending", 0);
                }else{
                    Fundu.Helpers.Notify.notify("sms", "identification.sms.state.send.sending", 0);
                }

                $.ajax({
                    type: "POST",
                    contentType: "application/json",
                    data: JSON.stringify(data),
                    url: jsRoutes.controllers.VerificationCtrl.startVerification().url,
                    cache: false
                })

                .done(function (res) {
                    showVerify();

                    if(resending){
                        Fundu.Helpers.Notify.success("sms", "identification.sms.state.resend.sent", 3000);
                    }else{
                        Fundu.Helpers.Notify.success("sms", "identification.sms.state.send.sent", 3000);
                    }
                    _sending = false;
                })

                .fail(function (res, error) {
                    // 406 is not returned as JSON, why??
                    if (res && res.responseJSON && "error" in res.responseJSON){
                        // Skip timeout with 0
                        Fundu.Helpers.Notify.error("sms", res.responseJSON.error, 3000);
                    } else {
                        _error = Fundu.Helpers.Defaults.Response.error("sms", error);
                    }
                    _sending = false;
                    _messageQuota = true; // Reset quota for retry
                });

                _messageQuota = false;

                // Freez quot for given time
                setTimeout(function(){
                    _messageQuota = true;
                    _sending = false;
                }, _waitTime);

            } else {
                Fundu.Helpers.Notify.error("sms", "error.phone.notvalid", 3000);
                _messageQuota = true; // Reset quota for retry
            }
        } else {
            Fundu.Helpers.Notify.error("sms", Fundu.Helpers.Notify.translate("error.sms.wait", Math.ceil(_waitTime/1000/60)), true, 3000);
        }
    }

    // Public Methods
    ///////////////////////////

    var reSend = function(ev){
        if(ev)
            ev.preventDefault();

        if(_sending) return false;
        return send(ev, true);
    }

    var verify = function(ev){
        if(ev)
            ev.preventDefault();

        if(_error || _sending) return false;

        // Check that the number is still correct
        if(validateCode()){

            // Build data
            var data = {
                code: _codeInput.val()
            };

            // Send sms message
            Fundu.Helpers.Notify.notify("sms", "identification.sms.state.waiting");

            $.ajax({
                type: "POST",
                contentType: "application/json",
                data: JSON.stringify(data),
                url: jsRoutes.controllers.VerificationCtrl.verifyCode().url,
                cache: false
            })

            .done(function (res) {
                finalize();
            })

            .fail(function (res, error) {
                // 406 is not returned as JSON, why??
                if (res && res.responseJSON && "error" in res.responseJSON){
                    // Skip timeout with 0
                    Fundu.Helpers.Notify.error("sms", res.responseJSON.error, 3000);
                } else {
                    _error = Fundu.Helpers.Defaults.Response.error("sms", error);
                }
            });

        } else {
            Fundu.Helpers.Notify.error("sms", "error.sms.pin.input", 3000);
        }
    }

    // Reveal public methods
    return {
        init: initialize
    }
})(jQuery)
