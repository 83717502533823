Fundu.Helpers.Validate = (function() {

    // Source: http://emailregex.com/
    var _emailValidate = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|tech|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

    // Uses XRegExp Plugin for \p support
    var _passwordValidate = '^(?=.*[\\p{Ll}])(?=.*[\\p{Lu}])(?=.*\\p{N})(.*[\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]){8,}$';

    // Uses XRegExp Plugin for \p support
    var _passwordTestLowercase = '^(?=.*\\p{Ll})(.*)$';

    // Uses XRegExp Plugin for \p support
    var _passwordTestUppercase = '^(?=.*\\p{Lu})(.*)$';

    // Uses XRegExp Plugin for \p support
    var _passwordTestNumber = '^(?=.*\\p{N})(.*)$';

    // Check that there is no :// or // in the redirects (only allow redirects in domain)
    var _internalUrlTest = /\:?(\/\/)/gm;

    // Allowed actions, should generate from scala
    var _tupasActions = [
        "registration",
        "login",
        "invest",
        "guarantee",
        "settings",
        "org-settings",
        "org-creation",
        "transfer-to-bank",
        "other"
    ]

    var _simulations = [
        "new-user",
        "investor-user",
        "company-user",
        "advisor-user",
        "foreign-user"
    ]

    // Public Methods
    ///////////////////////////

    var validateAction = function(action) {
        for (var index in _tupasActions) {
            if (_tupasActions[index] === action)
                return true;
        }
        return false;
    }

    var validateSimulation = function(value) {
        for (var index in _simulations) {
            if (_simulations[index] === value)
                return true;
        }
        return false;
    }

    var getSimulations = function() {
        return _simulations;
    }

    var validateEmail = function(email) {
        return _emailValidate.exec(email) === null ? false : true;
    }

    var validatePassword = function(password) {
        return XRegExp(_passwordValidate).test(password);
    }

    // Returns a list of criteria that have been passed
    var testPassword = function(password) {
        var results = {
            length: false,
            lowercase: false,
            uppercase: false,
            number: false
        }

        if(!password){
            return results;
        }
        else if(typeof password === "number"){
            results.number = true;
            return results;
        }
        else if(typeof password !== "string"){
            return results;
        }

        results.length = (password.length >= 8) ? true : false;
        results.lowercase = XRegExp(_passwordTestLowercase).test(password);
        results.uppercase = XRegExp(_passwordTestUppercase).test(password);
        results.number = XRegExp(_passwordTestNumber).test(password);

        return results;
    }


    var validateIsInternalUrl = function(url) {
        return _internalUrlTest.exec(url) === null ? true : false;
    }

    return {
        email: validateEmail,
        password: validatePassword,
        internalUrl: validateIsInternalUrl,
        action: validateAction,
        simulation: validateSimulation,
        Get: {
            simulations: getSimulations
        },
        Test: {
            password: testPassword
        }
    };
})();
