
Fundu.Pages.Credentials = (function($) {

    // Properties
    ///////////////////////////
    var _eIdentificationRegistration = false;
    var _passportRegistration = false;

    var _eIdentificationRegistrationButton = false;
    var _passportRegistrationButton = false;

    var _hasFinnishEBanking = false;

    var _credentialsContainer = false;
    var _smsContainer = false;

    var _waitForSms = false;

    var _smsBackButton = false;

    // Private Methods
    ///////////////////////////
    var selectElements = function() {
        _eIdentificationRegistration = $("#eIdentificationRegistration");
        _passportRegistration = $("#passportRegistration");

        _eIdentificationRegistrationButton = $("#eIdentificationRegistrationButton");
        _passportRegistrationButton = $("#passportRegistrationButton");

        _hasFinnishEBanking = $("#hasFinnishEBanking");

        _credentialsContainer = $('#credentialsContainer');
        _smsContainer = $('#smsContainer');

        _smsBackButton = $('#smsBackButton');
    }

    var initializeButtons = function() {
        _eIdentificationRegistrationButton.on('click', startAuthentication);
        _passportRegistrationButton.on('click', startAuthentication);

        _hasFinnishEBanking.on('change', toggleIdentificationMethod);

        _smsBackButton.on('click', function() { showCredentials() });
    }

    var startAuthentication = function() {
        var buttonSelected = $(this).attr('id');

        // Validates inputted email and password against `RegistrationCtrl.validateEmailAndPw`
        var result = Fundu.Components.Registration.start();

        if (result) {
            result.done(
                function () {
                    if (buttonSelected === "eIdentificationRegistrationButton") {
                        _eIdentificationRegistrationButton.attr("disabled", "disabled");
                    }

                    setTimeout(function () {
                        if (buttonSelected === "eIdentificationRegistrationButton") {
                            startOidcIdentify();
                        } else {
                            showSMS();
                        }
                    }, 150);
                }
            );
        }
    }

    var toggleIdentificationMethod = function() {
        _eIdentificationRegistration.toggle(!this.checked);
        _passportRegistration.toggle(this.checked);

        if (this.checked) {
            Fundu.Components.RegistrationWizardSteps.setPassportFirstStepActive();
        } else {
            Fundu.Components.RegistrationWizardSteps.setTupasFirstStepActive();
        }
    }

    var startOidcIdentify = function () {
        window.location.href = "/identifications/auth?successRedirect=/registration/complete-oidc-ident";
    }

    var showSMS = function(){
        _credentialsContainer.addClass("hidden");
        _smsContainer.removeClass("hidden");

        Fundu.Components.RegistrationWizardSteps.setPassportSecondStepActive();
    }

    var showCredentials = function() {
        _credentialsContainer.removeClass("hidden");
        _smsContainer.addClass("hidden");
    }

    var initializeSMS = function(){
        Fundu.Components.Sms.init();
        _waitForSms.resolve(true);
    }

    // Init
    ///////////////////////////
    var initialize = function() {

        selectElements();
        initializeButtons();

        Fundu.Components.Registration.init();

        Fundu.Components.RegistrationWizardSteps.init();
        Fundu.Components.RegistrationWizardSteps.setTupasFirstStepActive();

        _waitForSms = $.Deferred();
        Fundu.Pages.Common.addListener(initializeSMS);

        return _waitForSms;
    }

    var finalize = function() {

    }

    // Reveal public methods
    return {
        init: initialize,
        finalize: finalize
    }

})(jQuery);
