"use strict";

// create the root namespace and making sure we're not overwriting it
var Fundu = Fundu || {};

// Initialize namespaces
Fundu.Components = Fundu.Components || {};
Fundu.Helpers = Fundu.Helpers || {};
Fundu.Pages = Fundu.Pages || {};
Fundu.Utils = Fundu.Utils || {};
