/*
 * module.js - The description of the module.
 */

Fundu.Components.Registration = (function($) {

    // State
    ///////////////////////////
    var _isInitialized = false;

    // Properties
    ///////////////////////////
    var _emailElement = false;
    var _emailValidateElement = false;
    var _passwordElement = false;
    var _passwordValidateElement = false;
    var _registrationForm = false;
    var _confirmElement = false;
    var _confirmValidateElement = false;
    var _passwordStrength = false;
    var _passwordInstructions = false;
    var _error = false;
    var _lockState = false;

    // Private Methods
    ///////////////////////////

    // Select elements from HTML
    var selectElements = function() {
        _emailElement = $("#registrationEmail");
        _emailValidateElement = $("#registrationEmail").parent().find('.validation');
        _passwordElement = $("#registrationPassword");
        _passwordValidateElement = $("#registrationPassword").parent().find('.validation');
        _registrationForm = $("#registrationForm");
        _confirmElement = $("#confirmPassword");
        _confirmValidateElement = $("#confirmPassword").parent().find('.validation');
        _passwordStrength = $("#passwordStrength");

        if(_registrationForm.length > 0){
            _passwordInstructions = _registrationForm.find('.password.instructions .validate');
        }
    }

    // Check that the elements exist and unbind them
    var checkElements = function() {
        /* Not needed in production, no harm just commented out to get every last performance gain we can get
        if (_registrationForm && loginForm.length === 0) {
            Fundu.Helpers.Notify.error("console", "Registration form is missing");
            _error = true;
            return false;
        }

        if (_emailElement && _emailElement.length === 0) {
            Fundu.Helpers.Notify.error("console", "E-mail field for registration missing");
            _error = true;
            return false;
        }

        if (_passwordStrength && _passwordStrength.length === 0) {
            Fundu.Helpers.Notify.error("console", "Password strength missing");
            _error = true;
            return false;
        }

        if (_passwordElement && _passwordElement.length === 0) {
            Fundu.Helpers.Notify.error("console", "Password field for registration missing");
            _error = true;
            return false;
        }

        if (_confirmElement && _confirmElement.length === 0) {
            Fundu.Helpers.Notify.error("console", "Password confirm field for registration missing");
            _error = true;
            return false;
        }*/

        // Update e-mail validation if exists
        if( _emailValidateElement.length > 0 )
            _emailElement.on('keyup', checkEmail);

		// Update progress bar and validation result on each keypress of password input if they exists
		if( _passwordValidateElement.length > 0 && _passwordStrength.length > 0 )
		     _passwordElement.on('keyup', checkPassword);

        // Update password confirmations validation if it has one
        if( _confirmValidateElement.length > 0 )
            _confirmElement.on('keyup', checkPassword);

        // Try to account for autofill incase it is active (even if ti shouldn't!!)
        setTimeout( function(){
            if( _emailValidateElement.length > 0 )
                checkEmail.call(_emailElement.first());

            if( _passwordValidateElement.length > 0 && _passwordStrength.length > 0 )
                checkPassword.call(_passwordElement.first());

            if( _confirmValidateElement.length > 0 )
                checkPassword.call(_confirmElement.first());
        }, 800);

        return true;
    }

    var lock = function() {
        _lockState = true;
        _registrationForm.addClass("processing");
    }

    var unlock = function() {
        _lockState = false;
        setTimeout(function(){
            _registrationForm.removeClass("processing");
        }, 300);
    }

    // Init
    ///////////////////////////
    var initialize = function() {
        if(_isInitialized) return true;

        selectElements();

        var success = checkElements();
        if (success) {

            _isInitialized = true;
            return true;
        }

        return false;
    }

    // Check inputs
    ///////////////////////////

    var checkEmail = function(ev) {
        // 1. Check e-mail validity
        // 2. Check is e-mail in used
        // 2.1. If possible avoid this being exploited by limiting addresses

        var result = Fundu.Helpers.Validate.email(_emailElement.val());

        setTimeout(function(){
            if(result){
                var className = "success";
            }
            else{
                var className = "fail";
            }

            _emailValidateElement.attr("class", className + " validation");
        }, 80);

        // When used from startRegister
        if(typeof ev === "undefined" )
            return result;
    }

    // Check password
    var checkPassword = function(ev) {
        // 1. Validate password
        // 2. Check confirm password
        var password = _passwordElement.val();
        var confirmValue = _confirmElement.val();
        var result = false;
        var valid = false;

        if(password){
            valid = Fundu.Helpers.Validate.password(password)
            result = zxcvbn(password);
        }

        if(_confirmValidateElement.length > 0){
            var confirm = "fail";
            // We check also for empty
            if(confirmValue !== "" && password === confirmValue)
                confirm = "success";
        }

        setTimeout(function(){
            if(valid && result){
                if(result.score < 3){
                    _passwordValidateElement.attr("class", "warning validation");
                }
                else{
                    _passwordValidateElement.attr("class", "success validation");
                }
                _passwordStrength.attr('class', "score-" + result.score + " password strength");

                if(confirm)
                    _confirmValidateElement.attr("class", confirm + " validation");

                if(_passwordInstructions)
                    _passwordInstructions.addClass("success");
                return true;
            }
            _passwordValidateElement.attr("class", "fail validation");
            _passwordStrength.attr('class', "error password strength");

            // Mark what parts of the password have failed
            if(_passwordInstructions){
                setTimeout(function(){
                    // We don't need to replace success with fail because the default state is fail
                    _passwordInstructions.removeClass("success");
                    var results = Fundu.Helpers.Validate.Test.password(password);

                    var passed = false;

                    for(var test in results){
                        if(results[test]){
                            if(!passed){
                                passed = "." + test;
                            } else {
                                passed = passed + ", ." + test;
                            }
                        }
                    }

                    if(passed){
                        _passwordInstructions.filter(passed).addClass("success");
                    }
                }, 80);
            }

            if(confirm)
                _confirmValidateElement.attr("class", confirm + " validation");
        }, 80);

        // When used from startRegister
        if(typeof ev === "undefined" ){
            if(password === "")
                return "missing";
            if(password !== confirmValue)
                return
            return ( valid && (confirmValue === password));
        }
    }

    var createErrorHTML = function(errors) {
        if (!errors || typeof errors === "undefined") return false;

        var errorOutput = "";
        var firstError = true;

        for (var key in errors) {

            if(key === "termsAccepted"){
                // Translate variable terms and conditions
                var variable = Fundu.Helpers.Notify.translate("error.registration.terms.not-accepted");
            }else{
                // Translate variable
                var variable = Fundu.Helpers.Notify.translate("error." + key);
            }

            // If we couldn't translate the variable, print it as is
            if (!variable) {
                Fundu.Helpers.Notify.error("console", "Unknown value: " + key);
                return false;
            }

            if (errors[key].length > 0) {
                var values = "";

                // We dont use a list if there is only one error
                if(errors[key].length > 1){
                    values = '<ul class="values">';
                }

                // Translate values
                for (var index in errors[key]) {
                    // Check that the value is a object with message and args
                    if ("message" in errors[key][index] && "args" in errors[key][index]) {


                        if(key === "termsAccepted"){
                            // Translate variable terms and conditions
                            var value = Fundu.Helpers.Notify.translate("error.registration.terms.not-accepted");
                        }else{
                            var value = Fundu.Helpers.Notify.translate(errors[key][index].message);
                        }

                        if (!value) {
                            Fundu.Helpers.Notify.error("console", "Error " + errors[key][index] + " not translated");
                            return false;
                        }

                        // If only one error use span, else use list
                        if(errors[key].length === 1){
                            if(firstError){
                                var valueHTML = '<span class="only value">' + value + '</span>';
                            }else{
                                var valueHTML = '<br/><span class="only value">' + value + '</span>';
                            }
                        } else{
                            var valueHTML = '<li class="value">' + value + '</li>';
                        }
                    } else {
                        Fundu.Helpers.Notify.error("console", "Incorrect error object");
                        return false;
                    }

                    values = values + "\n\r" + valueHTML;

                    // If this is required error, don't show any other errors
                    if (errors[key][index].message === "error.required" ||
                        errors[key][index].message === "error.required"
                    ){
                        break;
                    }
                }

                // We dont use a list if there is only one error
                if(errors[key].length > 1){
                    values = values + "</ul>";
                }

                // If we have more than one failed key, create ul > ul
                if(errors[key].length > 1){
                    // BUild UL for value
                    var errorHTML = '<ul class"' + key + ' error list>';
                    errorHTML += '<li class="' + key + ' key">' + variable + '</li>';
                    errorHTML += values;
                    errorHTML += '</ul>';
                }
                // If only one error, print that as an ul
                else{
                    errorHTML = values;
                }

                if (firstError) {
                    errorOutput = errorHTML;
                    firstError = false;
                } else {
                    errorOutput = errorOutput + "\n\r" + errorHTML;
                }
            } else {
                Fundu.Helpers.Notify.error("console", "No errors found for variable");
                return false;
            }
        }

        // If all errors where empty
        if (errorOutput === "") {
            return false;
        }

        return errorOutput;
    }

    // Public Methods
    ///////////////////////////

    // check e-mail and password, if ok, the backend will
    // create a session
    var startRegistration = function() {
        if (_error || _lockState) return false;

        var termsAccepted = $('#acceptTerms').is( ":checked" );

        // You must accept the terms
        if(!termsAccepted){
            Fundu.Helpers.Notify.error("registration", "error.registration.terms.not-accepted");
            return false;
        }

        var email = _emailElement.val();
        var password = _passwordElement.val();
        var confirmPassword = _confirmElement.val();
        var marketingOptIn = $('#marketingOptIn').is( ":checked" );

        if (!email && !password) {
            Fundu.Helpers.Notify.error("registration", "error.login.missing_both");
            return false;
        }

        if (!email) {
            Fundu.Helpers.Notify.error("registration", "error.email.missing");
            return false;
        } else if (!Fundu.Helpers.Validate.email(email)) {
            Fundu.Helpers.Notify.error("registration", "error.email.notvalid");
            return false;
        }

        if (!password) {
            Fundu.Helpers.Notify.error("registration", "error.password.missing");
            return false;
        } else if (!Fundu.Helpers.Validate.password(password)) {
            Fundu.Helpers.Notify.error("registration", "error.password.weak");
            return false;
        }


        // Confirm password check, must be === to password
        if (!confirmPassword) {
            Fundu.Helpers.Notify.error("registration", "error.password.confirm.missing");
            return false;
        } else if (confirmPassword !== password) {
            Fundu.Helpers.Notify.error("registration", "error.password.confirm.wrong");
            return false;
        }

        Fundu.Helpers.Notify.hide("registration");

        lock();

        var data = {
            "email": email,
            "password": password,
            "termsAccepted": true,
            "marketingOptIn": marketingOptIn.toString()
        };

        try {
            return $.ajax({
                type: "POST",
                contentType: "application/json",
                data: JSON.stringify(data),
                url: jsRoutes.controllers.RegistrationCtrl.validateEmailAndPw().url
            })

            .done(function(res) {
                // Redirect to frontpage
                if (res && "success" in res) {
                    unlock();
                    return true;
                } else {
                    Fundu.Helpers.Notify.error("console", "Error in response: " + res.responseText, true);
                    Fundu.Helpers.Notify.error("registration", "error.generic");
                    _error = true;
                    return false;
                }
            })

            .fail(function(res, error) {
                if (res && "errors" in res.responseJSON) {
                    var errorHTML = createErrorHTML(res.responseJSON.errors);

                    // Skip timeout with 0
                    Fundu.Helpers.Notify.html("error", "registration", errorHTML, 10000);
                } else {
                    _error = Fundu.Helpers.Defaults.Response.error("registration", error);
                }
                unlock();
                return false;
            });
        } catch (error) {
            Fundu.Helpers.Notify.error("registration", "error.generic");
            unlock();
            return false;
        }
    };

    // Reveal public methods
    return {
        init: initialize,
        start: startRegistration
    };

})(jQuery);
