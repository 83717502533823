/*
 * module.js - The description of the module.
 */

Fundu.Components.Login = (function($) {

    // State
    ///////////////////////////
    var _isInitialized = false;

    // Properties
    ///////////////////////////
    var _emailElement = false;
    var _passwordElement = false;
    var _login_errorElement = false;
    var _loginForm = false;
    var _error = false;
    var _lockState = false;

    // Private Methods
    ///////////////////////////

    // Select elements from HTML
    var selectElements = function() {
        _emailElement = $("#loginEmail");
        _passwordElement = $("#loginPassword");
        _loginForm = $("#loginForm");
    }

    // Check that the elements exist and unbind them
    var checkElements = function() {

        /* Not needed in production, no harm just commented out to get every last performance gain we can get
        if (_loginForm && _loginForm.length === 0) {
            Fundu.Helpers.Notify.error("console", "Login form is missing");
            _error = true;
            return false;
        }

        if (_emailElement && _emailElement.length === 0) {
            Fundu.Helpers.Notify.error("console", "E-mail field for login missing");
            _error = true;
            return false;
        }

        if (_passwordElement && _passwordElement.length === 0) {
            Fundu.Helpers.Notify.error("console", "Password field for login missing");
            _error = true;
            return false;
        }
        */

        return true;
    }

    var lock = function() {
        _lockState = true;
        _loginForm.addClass("processing");
    }

    var unlock = function() {
        _lockState = false;
        setTimeout(function(){
            _loginForm.removeClass("processing");
        }, 300);
    }

    // Init
    ///////////////////////////
    var initialize = function() {
        if(_isInitialized) return true;

        selectElements();

        var success = checkElements();
        if (success) {

            _isInitialized = true;
            return true;
        }

        return false;
    }

    // Public Methods
    ///////////////////////////

    /// Login to site
    var login = function(ev) {
        if (ev) {
            ev.preventDefault();
        }

        if (_error || _lockState) return false;

        var email = _emailElement.val();
        var password = _passwordElement.val();

        if (!email && !password) {
            Fundu.Helpers.Notify.error("login", "error.login.missing.both");
            return;
        }

        if (!email) {
            Fundu.Helpers.Notify.error("login", "error.email.missing");
            return;
        } else if (!Fundu.Helpers.Validate.email(email)) {
            Fundu.Helpers.Notify.error("login", "error.email.notvalid");
            return;
        }

        if (!password) {
            Fundu.Helpers.Notify.error("login", "error.password.missing");
            return;
        } else if (!Fundu.Helpers.Validate.password(password)) {
            Fundu.Helpers.Notify.error("login", "error.login.bad.credentials");
            return;
        }

        Fundu.Helpers.Notify.hide("login");

        // Make sure user can't spam the login endpoint
        lock();

        var data = {
            "email": email,
            "password": password
        };

        try {
            $.ajax({
                type: "POST",
                contentType: "application/json",
                data: JSON.stringify(data),
                url: jsRoutes.controllers.AuthCtrl.doLogin().url
            })

            .done(function(res) {
                // Redirect to frontpage
                if (res && "redirect" in res) {
                    // Make sure the redirect is for this domain only
                    if (Fundu.Helpers.Validate.internalUrl(res.redirect)) {
                        window.location.href = res.redirect;
                        return false;
                    } else {
                        // The toString is because we can't be sure of what is inside the redirect
                        Fundu.Helpers.Notify.error("console", "A invalid redirect noticed:" + redirect.toString());
                        Fundu.Helpers.Notify.error("login", "error.generic");
                        _error = true;
                        return false;
                    }
                } else {
                    Fundu.Helpers.Notify.error("console", "Error in response: " + res.responseText, true);
                    Fundu.Helpers.Notify.error("login", "error.generic");
                    _error = true;
                    return false;
                }
            })

            .fail(function(res, error) {
                if (res.responseJSON && "error" in res.responseJSON) {
                    Fundu.Helpers.Notify.error("login", res.responseJSON.error);
                } else {
                    if (error === "error") {
                        Fundu.Helpers.Notify.error("login", "error.try_again");
                    } else if (error === "parsererror") {
                        Fundu.Helpers.Notify.error("login", "error.generic");
                        _error = true;
                        return false;
                    } else if (error === "timeout") {
                        Fundu.Helpers.Notify.error("login", "error.no_connection");
                    } else if (error === "abort") {
                        Fundu.Helpers.Notify.error("login", "error.aborted");
                    }
                }
                unlock();
                return false;
            });
        } catch (error) {
            Fundu.Helpers.Notify.error("login", "error.generic");
            unlock();
            return false;
        }
    };

    // Reveal public methods
    return {
        init: initialize,
        login: login
    };

})(jQuery);
