Fundu.Components.RegistrationWizardSteps = (function($) {

    // Properties
    ///////////////////////////
    var _stepsContainer = false;
    var _navbarLeft = false;
    var _navbarRight = false;

    var _tupasStepsContainer = false;

    var _tupasFirstStep = false;
    var _tupasSecondStep = false;
    var _tupasThirdStep = false;
    var _tupasFourthStep = false;

    var _passportStepsContainer = false;

    var _passportFirstStep = false;
    var _passportSecondStep = false;
    var _passportThirdStep = false;
    var _passportFourthStep = false;
    var _passportFifthStep = false;

    var _allSteps = false;

    var selectElements = function () {
        _stepsContainer = $('#registrationWizardStepsContainer');

        _navbarLeft = $('.navbar-left');
        _navbarRight = $('.navbar-right');

        _tupasStepsContainer = $('#tupasStepsContainer');

        _tupasFirstStep = $('#tupasFirstStep');
        _tupasSecondStep = $('#tupasSecondStep');
        _tupasThirdStep = $('#tupasThirdStep');
        _tupasFourthStep = $('#tupasFourthStep');

        _passportStepsContainer = $('#passportStepsContainer');

        _passportFirstStep = $('#passportFirstStep');
        _passportSecondStep = $('#passportSecondStep');
        _passportThirdStep = $('#passportThirdStep');
        _passportFourthStep = $('#passportFourthStep');
        _passportFifthStep = $('#passportFifthStep');

        _allSteps = $('#registrationWizardStepsContainer li');
    };

    // Private Methods
    ///////////////////////////
    var activateTupasSteps = function() {
        resetSteps();
        hideNavbarElements();

        showStepsContainer();

        _tupasStepsContainer.removeClass("hidden");
    }

    var activatePassportSteps = function() {
        resetSteps();
        hideNavbarElements();

        showStepsContainer();

        _passportStepsContainer.removeClass("hidden");
    }

    var resetSteps = function() {
        _navbarLeft.removeClass("hidden");
        _navbarRight.removeClass("hidden");

        _stepsContainer.addClass("hidden");
        _tupasStepsContainer.addClass("hidden");
        _passportStepsContainer.addClass("hidden");

        _allSteps.removeClass("current");
    }

    var hideNavbarElements = function() {
        _navbarLeft.addClass("hidden");
        _navbarRight.addClass("hidden");
    }

    var showStepsContainer = function() {
        _stepsContainer.removeClass("hidden");
    }

    // Public Methods
    ///////////////////////////
    var setTupasFirstStepActive = function() {
        activateTupasSteps();
        _tupasFirstStep.addClass("current");
    }

    var setTupasSecondStepActive = function() {
        activateTupasSteps();
        _tupasSecondStep.addClass("current");
    }

    var setTupasThirdStepActive = function() {
        activateTupasSteps();
        _tupasThirdStep.addClass("current");
    }

    var setTupasFourthStepActive = function() {
        activateTupasSteps();
        _tupasFourthStep.addClass("current");
    }

    var setPassportFirstStepActive = function() {
        activatePassportSteps();
        _passportFirstStep.addClass("current");
    }

    var setPassportSecondStepActive = function() {
        activatePassportSteps();
        _passportSecondStep.addClass("current");
    }

    var setPassportThirdStepActive = function() {
        activatePassportSteps();
        _passportThirdStep.addClass("current");
    }

    var setPassportFourthStepActive = function() {
        activatePassportSteps();
        _passportFourthStep.addClass("current");
    }

    var setPassportFifthStepActive = function() {
        activatePassportSteps();
        _passportFifthStep.addClass("current");
    }


    // Init
    ///////////////////////////
    var initialize = function() {
        selectElements();

        return true;
    }

    // Reveal public methods
    return {
        init: initialize,
        setTupasFirstStepActive: setTupasFirstStepActive,
        setTupasSecondStepActive: setTupasSecondStepActive,
        setTupasThirdStepActive: setTupasThirdStepActive,
        setTupasFourthStepActive: setTupasFourthStepActive,
        setPassportFirstStepActive: setPassportFirstStepActive,
        setPassportSecondStepActive: setPassportSecondStepActive,
        setPassportThirdStepActive: setPassportThirdStepActive,
        setPassportFourthStepActive: setPassportFourthStepActive,
        setPassportFifthStepActive: setPassportFifthStepActive
    }
})(jQuery);