Fundu.Pages.Passport = (function($) {

    // Init
    ///////////////////////////
    var initialize = function() {

        Fundu.Components.RegistrationWizardSteps.init();
        Fundu.Components.RegistrationWizardSteps.setPassportThirdStepActive();

        return $.Deferred().resolve(true);
    }

    // Reveal public methods
    return {
        init: initialize
    };

})(jQuery);