/*
 * module.js - The description of the module.
 */

Fundu.Utils.Loader = (function($) {

    // Properties
    ///////////////////////////


    // Private Methods
    ///////////////////////////

    // Convert css class name to camelcase
    // Unit tests:
    // "LOGIN-REGISTRATION", "lOGiN-ReGistraTION" => LoginRegistration
    // "login-registration", "Login Registration", "Login Registration" => LoginRegistration
    // "loGIn RegistRtration", "login registration" => LoginRegistration
    // "l" => L
    // "COMMON", "Common", "cOmmOn", "common" => Common
    // {}, [], function(), true, false, 299, 299.99, "", null, undefined => false
    var convert = function(name) {
        if (!name || typeof name !== "string") return false;

        // Check that the name isn't common
        if (name.toLowerCase() !== "common") {
            var transformed = name;

            // CASE: name has - or white-space
            if (transformed.indexOf('-') > -1 || transformed.indexOf(' ') > -1) {
                // Example-case: Login-registration, Login Registration
                transformed = transformed.replace('-', ' ') // Replace - with spaces, example-case: Login registration, Login Registration
                    .toLowerCase() // Convert all letters to lowecase, example-case: login registration, login registration
                    .replace(/\b[a-z](?=[a-z]{2})/g, function(letter) { // For each word separated with space, make the first lette uppercase
                        return letter.toUpperCase(); // example-case: Login Registration, Login Registration
                    })
                    .replace(/\s+/g, ''); // Remeove white-space, example-case: LoginRegistration, LoginRegistration
            }

            // Case: one long word
            else {
                // Case: One long word
                // We already know with !name that the string isn't empty so we only need to
                // react to 1 letter and x amount of letters
                if (transformed.length > 1) {
                    var firstLetter = transformed.charAt(0).toUpperCase(); // Get the first letter and covert to uppercase
                    var restOfTest = transformed.toLowerCase().substr(1, transformed.length - 1); // Get rest of the string

                    transformed = firstLetter + restOfTest; // Combine
                }
                // Case: One letter
                // If the test is one letter long
                else {
                    transformed.toUpperCase();
                }
            }

            return transformed;
        }
        // If the name was common
        else {
            return "Common";
        }

        return false;
    }


    var fire = function(page, method, args) {
        if (!page || typeof page !== "string") return false;

        // Method must be set, must be a string and the value should be eather init or finalize
        if (!method || typeof method !== "string" || !(method !== "init" || method !== "finalize")) return false;

        // Does the page exists and is it a object
        // If the method exists and is it a function
        if (Fundu.Pages[page] && typeof Fundu.Pages[page] === 'object' &&
            Fundu.Pages[page][method] && typeof Fundu.Pages[page][method] === 'function') {
            return Fundu.Pages[page][method](args);
        }

        return false;
    }

    // Init
    ///////////////////////////
    var initialize = function() {

        // Deferred list
        var waitQueue = Array();

        // Fire common init JS
        var success = fire('Common', 'init');

        // Add to wait queuee
        waitQueue.push(success);

        var failed = false;

        // Fire page-specific init JS, and then finalize JS
        // We reverse because the body classes should go from specific to generic
        $.each(
            document.body.className.split(/\s+/).reverse(),
            function(i, classnm) {
                classnm = convert(classnm);

                if(classnm){
                    var result = fire(classnm, "init");

                    if(result){
                        result.done(function(){
                            fire(classnm, "finalize");
                        });

                        waitQueue.push(result);
                    }
                }
            });

        return $.when.apply($, waitQueue).done(function(){
            // Fire common finalize JS
            fire('Common', 'finalize');
        });
    }

    // Reveal public methods
    return {
        init: initialize
    };
})(jQuery);
