Fundu.Pages.LoginRegistration = (function($) {

    // Properties
    ///////////////////////////
    var _loginButton = false;
    var _eIdentificationButton = false;
    var _redirectUrlDiv = false;

    // Private Methods
    ///////////////////////////
    var selectElements = function() {
        _loginButton = $('#loginButton');
        _eIdentificationButton = $('#eIdentificationButton');
        _redirectUrlDiv = $('#oidcSuccessRedirectUrlDiv');
    }

    var initializeButtons = function() {
        _loginButton.on('click', Fundu.Components.Login.login);
        _eIdentificationButton.on('click', function() { identify(); });
    }

    var identify = function() {
        var redirectUrl = _redirectUrlDiv.text();

        window.location.href = "/identifications/auth?successRedirect=" + redirectUrl;
    }

    // Init
    ///////////////////////////
    var initialize = function() {

        selectElements();
        initializeButtons();

        Fundu.Components.Login.init();

        // Capture form submit (like when you press enter)
        $(document).on('submit', '#loginForm', Fundu.Components.Login.login);

        return $.Deferred().resolve(true);
    }

    var finalize = function() {

    }

    // Reveal public methods
    return {
        init: initialize,
        finalize: finalize
    }

})(jQuery);
